jQuery(document).ready(function($){
	var image = document.getElementsByClassName('whitelogo');
	new simpleParallax(image, {
		overflow: true
	});

	var image = document.getElementsByClassName('logoiconcolor');
	new simpleParallax(image, {
		overflow: true
	});

	var image = document.getElementsByClassName('semiright1');
	new simpleParallax(image, {
		overflow: true
	});

});

// https://simpleparallax.com/